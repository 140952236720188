import { createSlice } from '@reduxjs/toolkit';

export const DEFAULT_PWL_RANGE = [92.0, 94.0];

export const DEFAULT_COLOR_RANGE = 'default_color_range';

export const HexbinLayerTypes = {
  density: 'density',
  pass_count: 'pass_count',
  temperature: 'temperature',
};

export const DEFAULT_HEXBIN_LAYERS = [
  {
    id: HexbinLayerTypes.density,
    label: 'Density',
    show: true,
    layer_info: {
      source_name: 'densityHexGridSource',
      layer_name: 'densityHexGridLayerName',
      outline_layer_name: 'densityHexGridOutlineLayerName',
      range_units: '%MTD',
      color_range: [
        88, '#441566',  // purple
        89, '#636DFF',  // dark blue 
        90, '#00D4F5',  // light blue
        91, '#63FFF4',  // lighter blue
        92, '#65FF65',  // green
        93, '#FEF763',  // yellow
        94, '#FFB563',  // orange
        95, '#FF6364',  // red
        96, '#FF3333',  // dark red
      ],
      color_range_options: {
        default_color_range: [
          88, '#441566',  // purple
          89, '#636DFF',  // dark blue 
          90, '#00D4F5',  // light blue
          91, '#63FFF4',  // lighter blue
          92, '#65FF65',  // green
          93, '#FEF763',  // yellow
          94, '#FFB563',  // orange
          95, '#FF6364',  // red
          96, '#FF3333',  // dark red
        ],
        accessible_color_range: [
          88, '#002041',  // dark-blue
          89, '#004186',  // blue
          90, '#25486E',  // brown-blue
          91, '#704D1C',  // dark-brown
          92, '#9A6820',  // brown
          93, '#CE8A23',  // orange-brown
          94, '#FFB31F',  // orange
          95, '#FFE900',  // yellow
          96, '#FFF7AD',  // yellow-white
        ],
      },
    }
  },
  {
    id: HexbinLayerTypes.pass_count,
    label: 'Pass Count',
    show: false,
    layer_info: {
      source_name: 'passCountHexGridSource',
      layer_name: 'passCountHexGridLayerName',
      outline_layer_name: 'passCountHexGridOutlineLayerName',
      range_units: '',
      color_range: [
        0, '#5537A8',  // purple
        2, '#9836B9',  // light purple
        4, '#CB619C',  // lighter purple
        6, '#EE9079',  // peach
        8, '#F9AA66',  // light orange
        10, '#FCED52', // yellow
      ],
      color_range_options: {
        default_color_range: [
          0, '#5537A8',  // purple
          2, '#9836B9',  // light purple
          4, '#CB619C',  // lighter purple
          6, '#EE9079',  // peach
          8, '#F9AA66',  // light orange
          10, '#FCED52', // yellow
        ],
        accessible_color_range: [
          0, '#002041',  // dark-blue
          2, '#25486E',  // blue
          4, '#704D1C',  // brown
          6, '#FFB31F',  // orange-brown
          8, '#FCED52',  // orange
          10, '#FFF7AD', // yellow-white
        ],
      },
    }
  },
  {
    id: HexbinLayerTypes.temperature,
    label: 'Temperature',
    show: false,
    layer_info: {
      source_name: 'tempHexGridSource',
      layer_name: 'tempHexGridLayerName',
      outline_layer_name: 'tempHexGridOutlineLayerName',
      range_units: '\u00B0F',
      color_range: [
        150, '#3A8BBB',  // blue
        180, '#F7FCB3',  // yellow
        210, '#FFE798',  // yellow-orange
        240, '#FE9F63',  // orange
        270, '#D7434F',  // red
        300, '#871D25',  // dark-red
      ],
      color_range_options: {
        default_color_range: [
          150, '#3A8BBB',  // blue
          180, '#F7FCB3',  // yellow
          210, '#FFE798',  // yellow-orange
          240, '#FE9F63',  // orange
          270, '#D7434F',  // red
          300, '#871D25',  // dark-red
        ],
        accessible_color_range: [
          150, '#00428E',  // blue
          180, '#99660C',  // brown
          210, '#B57818',  // brown-orange
          240, '#FFB31F',  // orange
          270, '#FFE900',  // yellow
          300, '#FFF7AD',  // yellow-white
        ],
      },
    }
  },
];

const initialState = {
  hexbinLayers: DEFAULT_HEXBIN_LAYERS,
  pwlRange: DEFAULT_PWL_RANGE,
  curColorRange: DEFAULT_COLOR_RANGE,
};

export const densityMapSlice = createSlice({
    name: 'densityMap',
    initialState,
    reducers: {
      setActiveHexbinLayer: (state, action) => {
        state.hexbinLayers = state.hexbinLayers.map(layer => {
          return { ...layer, show: layer.id === action.payload.id };
        });
      },
      setLayerRange: (state, action) => {
        const { layerId, range } = action.payload;

        state.hexbinLayers = state.hexbinLayers.map(layer => {
          if (layer.id === layerId) {
            layer.layer_info.color_range = range;
          }
          return layer;
        });
      },
      setLayerRanges: (state, action) => {
        const { densityRange, passCountRange, temperatureRange } = action.payload;

        const newHexbinLayers = state.hexbinLayers.map(layer => {
          switch (layer.id) {
            case HexbinLayerTypes.density:
              layer.layer_info.color_range = densityRange;
              break;
            case HexbinLayerTypes.pass_count:
              layer.layer_info.color_range = passCountRange;
              break;
            case HexbinLayerTypes.temperature:
              layer.layer_info.color_range = temperatureRange;
              break;
            default:
              break;
          }
          return layer;
        });

        state.hexbinLayers = newHexbinLayers;
      },
      setPwlRange: (state, action) => {
        state.pwlRange = action.payload;
      },
      setColorRange: (state, action) => {
        const colorRange = action.payload;
        state.setColorRange = colorRange;

        state.hexbinLayers = state.hexbinLayers.map(layer => {
          // Compare "color_range" state to maintain any changes when switching between color options
          const defaultColorRange = layer.layer_info.color_range_options[colorRange];
          const oldColorRange = layer.layer_info.color_range;

          const defaultColorRangeValues = defaultColorRange.filter((_, index) => index % 2 === 0);
          const oldColorRangeValues = oldColorRange.filter((_, index) => index % 2 === 0);

          const newColorRange = [];

          for(let i = 0; i < defaultColorRangeValues.length; i++){
            if(oldColorRangeValues.includes(defaultColorRangeValues[i])){
              newColorRange.push(defaultColorRange[i * 2]);
              newColorRange.push(defaultColorRange[i * 2 + 1]);
            }
          }
          
          layer.layer_info.color_range = newColorRange;

          return layer;
        });
      },
    },
});

export const {
  setActiveHexbinLayer,
  setLayerRange,
  setLayerRanges,
  setPwlRange,
  setColorRange,
} = densityMapSlice.actions;

export const selectHexbinLayers = (state) => state.densityMap.hexbinLayers;
export const selectPwlRange = (state) => state.densityMap.pwlRange;
export const selectCurColorRange = (state) => state.densityMap.curColorRange;

export default densityMapSlice.reducer;
