import React from "react";
import ReactDOM from "react-dom";
import "bootstrap";
import "@popperjs/core";
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./assets/scss/index.scss";
import "./assets/scss/theme-style.css"

import App from "./App";
import { persistor, default as store } from "./features/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import ThemeProvider from './theme';
import RequireAuth from "./@core/auth/RequireAuth";
import RequireGuest from "./@core/auth/RequireGuest";

import { PersistGate } from "redux-persist/lib/integration/react";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RequireGuest><LoginPage /></RequireGuest>} />
            <Route path="/*" element={<RequireAuth><App /></RequireAuth>} />
          </Routes>
        </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </LocalizationProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
