/**
 * DensityMapPage Component
 * 
 * This component is responsible for rendering and managing the density map page of the application.
 * It uses various utility functions for data processing, mapping, and formatting.
 * 
 * After retrieving the project data, the geo points are processed and passed to a mapping component.
 * 
 * The processing consists of "expanding" the points by creating duplicates of that point 
 * and dispersing them in a rectangle centered on the original point (this is to represent the roller width).
 * Those values are then added to a hexbin grid, and additional values are calculated, such as average density and the PWL table.
 * 
 * Because the points are "expanded," there can be unexpected scenarios; in particular, the same point can 
 * appear in more than one hexbin or can appear the same hexbin multiple times.
 */

import { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from 'react-router-dom';
import { axiosApiInstance } from 'src/@core/utils/axios';
import { useDispatch, useSelector } from "react-redux";
import { getDataKeyByKey } from "src/features/dataKeySlice";
import * as turf from "@turf/turf";
import Papa from 'papaparse';
import { selectPwlRange } from "src/features/densityMapSlice";
// MUI
import {
  Box, Button, Typography,
  Checkbox, FormControlLabel, FormGroup,
  Backdrop, CircularProgress
} from "@mui/material";
import StopIcon from '@mui/icons-material/Stop';
import SkipNextIcon from '@mui/icons-material/SkipNext';
// components
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import DensityMap, { DEFAULT_MAP_LAYERS } from './components/map/DensityMap';
import { iconSet } from "../../@core/data/icons";
// util
import { formatDate, formatUtcToLocalTz } from "src/@core/utils/dateTimeFormatter";
import { HexbinProcessor } from 'src/libs/rtd-hex-map-js';
import { ToastWarning, ToastError } from "src/components/Toast";

// ----------------------------------------------------------------------

const DEFAULT_ZOOMS = {
  start_zoom: 2,
  feature_zoom: 17
};
const replayFeaturesPerUpdate = 12;
const replayWaitTime = 120; // ms
const hexgridResolution = 15; // Resolution: 0 (coarsest) and 15 (finest)
const breadcrumbs = [
  { route: "/Home", label: "Home" },
  { route: "/projects", label: "Projects" },
  { route: "", label: "Density Map" }
];
const rectangleDimensions = {
  width: 1.8288, // 1.8288 meters = 6ft
  height: 0.6096, // 0.6096 meters = 2ft
  cols: 6,
  rows: 2,
}
const DEFAULT_FEATURE_COLLECTION = {
  type: 'FeatureCollection',
  features: []
}

let pointIdCounter = 0;
function generateUniquePointId() {
  return `${++pointIdCounter}`;
}

const DEFAULT_PROJECT_INFO = {
  projectName: '-',
  startDate: '',
  endDate: '',
  queryStartDate: '',
  queryEndDate: '',
};

// ----------------------------------------------------------------------

const DensityMapPage = () => {
  const dispatch = useDispatch();
  const { dataKeyByKey } = useSelector((state) => state.dataKey);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dataKey = searchParams.get("dataKey");
  const [project, setProject] = useState(DEFAULT_PROJECT_INFO);
  const fileInputRef = useRef(null);
  const coreSampleFileInputRef = useRef(null);
  const [loadingInfo, setLoadingInfo] = useState({ loading: false, message: '' });
  const [coreSamples, setCoreSamples] = useState([]);
  const [origGeojsonData, setOrigGeojsonData] = useState(null);
  const [geojsonData, setGeojsonData] = useState(null);
  const [rectGeojson, setRectGeojson] = useState(null);
  const hexgridFeatureIndexRef = useRef({});
  const hexgridFeatureCollectionRef = useRef(DEFAULT_FEATURE_COLLECTION);
  const intervalIdRef = useRef(null);
  const [isReplaying, setIsReplaying] = useState(false);
  const [showMapLayers, setShowMapLayers] = useState(DEFAULT_MAP_LAYERS);
  const replayFeaturesRef = useRef(0);
  const [dataVersion, setDataVersion] = useState(0);
  const [cameraConfig, setCameraConfig] = useState({});
  const [iconInfo, setIconInfo] = useState({});
  const pwlRange = useSelector(selectPwlRange);
  const [pwlTable, setPwlTable] = useState([]);
  const [settings, setSettings] = useState({ showDebugLayers: false })

  // init pwl table
  useEffect(() => {
    if (!pwlRange) return;

    const newPwlTable = HexbinProcessor.computePwlTable(hexgridFeatureCollectionRef.current, pwlRange);
    setPwlTable(newPwlTable);
  }, [pwlRange]);

  // clean up on unmount
  useEffect(() => {
    return () => {
      if (isReplaying) {
        clearInterval(intervalIdRef.current);
      }
    };

  }, [isReplaying]);

  useEffect(() => {
    if (!geojsonData) return;

    // clear old data
    hexgridFeatureIndexRef.current = {};
    hexgridFeatureCollectionRef.current = DEFAULT_FEATURE_COLLECTION;

    console.time("updateHexbinData");
    updateHexbinData(0, geojsonData.features.length);
    console.timeEnd("updateHexbinData");

    console.time("compuetePwlTable");
    const newPwlTable = HexbinProcessor.computePwlTable(hexgridFeatureCollectionRef.current, pwlRange);
    setPwlTable(newPwlTable);
    console.timeEnd("compuetePwlTable");

    const featureCenter = turf.center(geojsonData);
    setCameraConfig({ center: featureCenter.geometry.coordinates, zoom: DEFAULT_ZOOMS.feature_zoom });

    updateCoreSamples();
  }, [geojsonData]);

  useEffect(() => {
    if (!origGeojsonData) return;

    console.time("updateRectData");
    updateRectData();
    console.timeEnd("updateRectData");
  }, [origGeojsonData]);

  // query project data
  useEffect(() => {
    if (!id) return;

    loadProjectData(id);
  }, [id]);

  // clean up on unmount
  useEffect(() => {
    return () => {
      hexgridFeatureIndexRef.current = {};
      hexgridFeatureCollectionRef.current = DEFAULT_FEATURE_COLLECTION;
    };
  }, []);

  const updateHexbinData = (curIndex, endIndex) => {
    const hexgridIndex = hexgridFeatureIndexRef.current; // just renaming the reference variable to be shorter
    const updatedIndices = {};

    for (let i = curIndex; i < endIndex; i++) {
      const point = geojsonData.features[i];

      if (!point.properties.density) continue; // skip if no density

      const index = HexbinProcessor.getHexbinIndex(point, hexgridResolution);

      if (!updatedIndices[index]) {
        updatedIndices[index] = index;
      }

      point.properties.hexbinIndex = index;

      HexbinProcessor.updateHexgridFeatureWithPoint(hexgridIndex, point);
    }

    hexgridFeatureCollectionRef.current = turf.featureCollection(Object.values(hexgridFeatureIndexRef.current));
  }

  const updateRectData = () => {
    const rectFeatures = origGeojsonData.features.map(point => {
      return HexbinProcessor.getRectangleFeature(
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
        rectangleDimensions.width,
        rectangleDimensions.height,
        point.properties.heading,
      );
    });

    setRectGeojson({
      type: 'FeatureCollection',
      features: rectFeatures
    });
  }

  const loadProjectData = async (id) => {
    setLoadingInfo({ loading: true, message: 'Fetching project data...' });

    const projectInfo = await getProject(id);

    if (!projectInfo) {
      alert('Project not found');
      setLoadingInfo({ loading: false, message: '' });
    }

    setProject({ ...projectInfo });

    if(!dataKey){
      return;
    }

    dispatch(getDataKeyByKey(dataKey));

    console.time("queryProjectData");

    const projectData = await getProjectData(projectInfo.projectKey, dataKey);

    setLoadingInfo({ loading: true, message: 'Processing data and rendering map...' });
    await new Promise(resolve => setTimeout(resolve, 0)); // Allow the screen to redraw

    if (!Array.isArray(projectData)) {
      console.log('Invalid map data');
    } else if (projectData.length == 0) {
      console.log('No map data found');
    }

    console.timeEnd("queryProjectData");

    processProjectData(projectData);

    setLoadingInfo({ loading: false, message: '' });
  }

  const getProject = async (id) => {
    try {
      const url = `/api/v2/projects/${id}`
      const { data } = await axiosApiInstance.get(url);

      return data;
    } catch (error) {
      console.log("error: ', error");
    }
  }

  const getProjectData = async (projectKey, dataKey) => {
    try {
      const url = `/api/v2/data-keys/data`;
      const params = { dataKeys: dataKey }

      const { data } = await axiosApiInstance.get(url, { params });

      return data;
    } catch (error) {
      console.log('error: ', error);
      if (error?.response?.data?.messageKey) {
        ToastError(error.response.data.messageKey);
      } else if(error?.response?.data) {
        ToastError(error.response.data);
      }
    }
  }

  const handleLayerChange = (mapLayer, event) => {
    const value = event.target.checked;
    const newMapLayer = { ...mapLayer, show: value };

    setShowMapLayers(showMapLayers.map(layer => {
      if (layer.id === mapLayer.id) {
        return newMapLayer;
      }

      return layer;
    }));
  };

  const processProjectData = (data) => {
    if(!data || !Array.isArray(data) || data.length == 0){
      return;
    }

    // Warning user that some rows could not be parsed
    if(data.some(row => !isPointDataValid(row, 'api'))){
      ToastWarning("Data points with an invalid format have been excluded.");
    }

    const features = data.filter(row => isPointDataValid(row, 'api')).map((row) => {
      const longitude = parseFloat(row.Longitude);
      const latitude = parseFloat(row.Latitude);
      const properties = {
        passID: row.PassID,
        heading: parseFloat(row.Heading),
        density: parseFloat(row.Density),
        speed: parseFloat(row.Speed),
        temperature: parseFloat(row.Temperature),
        time: row.TimeStamp,
      };

      return turf.point([longitude, latitude], properties);
    });

    const featureCollection = turf.featureCollection(features);

    setOrigGeojsonData(featureCollection);
    expandPoints(featureCollection);
  }

  const handleFileChange = async (event) => {
    const expectedHeaders = ['longitude', 'latitude', 'heading', 'density', 'passID', 'speed', 'temperature', 'time'];
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    setLoadingInfo({ loading: true, message: 'Loading file...' });

    console.time("readFile");

    try {
      let data;

      if (file.name.endsWith('.geojson')) {
        data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            resolve(JSON.parse(e.target.result));
          };
          reader.onerror = reject;
          reader.readAsText(file);
        });
      }else if (file.name.endsWith('.csv')) {
        data = await new Promise((resolve, reject) => {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              if (result.data.length < 1) {
                const msg = "CSV file does not have the expected headers: " + expectedHeaders.join(', ');
                console.error(msg);
                reject(new Error(msg));
                return;
              }

              const fileHeaders = Object.keys(result.data[0]);
              const hasCorrectHeaders = expectedHeaders.every(header => fileHeaders.includes(header));

              if (!hasCorrectHeaders) {
                const msg = "CSV file does not have the expected headers: " + expectedHeaders.join(', ');
                console.error(msg);
                reject(new Error(msg));
                return;
              }

              // Warning user that some rows could not be parsed
              if(result.data.some(row => !isPointDataValid(row, 'csv'))){
                ToastWarning("Data points with an invalid format have been excluded.");
              }
              
              // convert the CSV data to GeoJSON
              const features = result.data.filter(row => isPointDataValid(row, 'csv')).map((row) => {
                const longitude = parseFloat(row.longitude);
                const latitude = parseFloat(row.latitude);

                const properties = {
                   heading: row.heading,
                   density: row.density,
                   passID: row.passID,
                   speed: row.speed,
                   temperature: row.temperature,
                   time: row.time,
                };
                return turf.point([longitude, latitude], properties);
              });

              resolve(turf.featureCollection(features));
            },
            error: reject
          });
        });
      } else {
        throw new Error('Unsupported file type');
      }

      // have to be numbers for the map library
      data.features.forEach(point => {
        point.properties.heading = parseFloat(point.properties.heading);
        point.properties.density = parseFloat(point.properties.density);
        point.properties.passID = parseFloat(point.properties.passID);
        point.properties.speed = parseFloat(point.properties.speed);
        point.properties.temperature = parseFloat(point.properties.temperature);
      });

      console.timeEnd("readFile");

      setLoadingInfo({ loading: true, message: 'Processing data and rendering map...' });
      await new Promise(resolve => setTimeout(resolve, 0)); // Allow the screen to redraw

      setOrigGeojsonData(data);
      expandPoints(data);
    } catch (error) {
      console.timeEnd("readFile");
      const msg = error.message || "Error reading file";
      alert(msg);
    } finally {
      setLoadingInfo({ loading: false, message: '' });
    }
  };

  const isPointDataValid = (data, type="csv") => {
    if(type == 'csv'){
      const { longitude, latitude, heading, density, passID, speed, temperature } = data;
      if ([longitude, latitude, heading, density, passID, speed, temperature].some(isNaN)) {
        return false;
      }
    }else if(type == 'api'){
      const { Longitude, Latitude, Heading, Density, Speed, Temperature } = data;
      if ([Longitude, Latitude, Heading, Density, Speed, Temperature].some(isNaN)) {
        return false;
      }
    }

    return true;
  }

  const expandPoints = (data) => {
    console.time("expandPoints");
    const pointFeatures = [];

    // add an id to each point
    for (let i = 0; i < data.features.length; i++) {
      const point = data.features[i];
      point.properties.id = generateUniquePointId();
    }

    for (let i = 0; i < data.features.length; i++) {
      const point = data.features[i];

      const curPoints = HexbinProcessor.expandPointInRectangle(
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
        rectangleDimensions.width,
        rectangleDimensions.height,
        rectangleDimensions.rows,
        rectangleDimensions.cols,
        point.properties.heading,
      );

      for (let j = 0; j < curPoints.length; j++) {
        const longitude = curPoints[j][0];
        const latitude = curPoints[j][1];
        const properties = { ...point.properties, source_point_id: point.properties.id };
        const geoJsonPoint = turf.point([longitude, latitude], properties);
        pointFeatures.push(geoJsonPoint);
      }
    }

    console.timeEnd("expandPoints");

    const featureCollection = turf.featureCollection(pointFeatures);

    setGeojsonData(featureCollection);

    setDataVersion(Date.now());
  }

  const handleCoreSampleFileChange = async (event) => {
    const expectedHeaders = ['latitude', 'longitude', 'core', 'lab_data'];
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    try {
      const data = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            if (result.data.length < 1) {
              const msg = "CSV file does not have the expected headers: " + expectedHeaders.join(', ');
              console.error(msg);
              reject(new Error(msg));
            }

            const fileHeaders = Object.keys(result.data[0]);
            const hasCorrectHeaders = expectedHeaders.every(header => fileHeaders.includes(header));

            if (hasCorrectHeaders) {
              resolve(result.data);
            } else {
              const msg = "CSV file does not have the expected headers: " + expectedHeaders.join(', ');
              console.error(msg);
              reject(new Error(msg));
            }
          },
          error: reject
        });
      });

      // add the column "estimate" to the data
      //data.forEach(row => {
      //  row.estimate = null;
      //});

      updateCoreSamples(data);
    } catch(error) {
      const msg = error.message || "Error reading file";
      alert(msg);
    }
  }

  /**
   * Update estimated density for core samples.
   * Data will be appended to the current core samples.
   */
  const updateCoreSamples = (data) => {
    let curCoreSamples = coreSamples;

    if(data) {
      curCoreSamples = curCoreSamples.concat(data);
    }

    const newCoreSamples = curCoreSamples.map(row => {
      let estimate = null;

      if (hexgridFeatureCollectionRef.current.features.length > 0) {

        const hexbin = HexbinProcessor.getHexgridFeatureFromCood(hexgridFeatureIndexRef.current, row.longitude, row.latitude, hexgridResolution)
        if (hexbin) {
          estimate = hexbin.properties.density;
        }
      }

      return {
        latitude: parseFloat(row.latitude),
        longitude: parseFloat(row.longitude),
        core: row.core,
        estimate: parseFloat(estimate),
        lab_data: parseFloat(row.lab_data)
      };
    });

    setCoreSamples(newCoreSamples);
  }

  const onReplayData = () => {
    if (!origGeojsonData) return;

    setIsReplaying(true);
    hexgridFeatureIndexRef.current = {};
    replayFeaturesRef.current = 0;

    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(replay, replayWaitTime);
  };

  const replay = () => {
    const curIndex = replayFeaturesRef.current;
    const endIndex = Math.min(curIndex + replayFeaturesPerUpdate, geojsonData.features.length);

    if (curIndex >= geojsonData.features.length) {
      console.log('should be clearning the interval');
      onStopReplay();
      return;
    }

    const lastFeature = geojsonData.features[endIndex - 1];

    setCameraConfig({
      center: lastFeature.geometry.coordinates,
      essential: true,
      speed: 0.5,
      curve: 1,
      duration: 1000
    });

    updateHexbinData(curIndex, endIndex);

    setIconInfo({
      heading: lastFeature.properties.heading,
      coordinates: lastFeature.geometry.coordinates
    });

    replayFeaturesRef.current = replayFeaturesRef.current + replayFeaturesPerUpdate;

    updateCoreSamples(); 

    setDataVersion(Date.now());
  };

  const onStopReplay = () => {
    clearInterval(intervalIdRef.current);
    setIsReplaying(false);
    setIconInfo({});
    setDataVersion(Date.now());
  }

  const onSkipReplay = () => {
    onStopReplay();
    hexgridFeatureIndexRef.current = {};
    updateHexbinData(0, geojsonData.features.length);
    updateCoreSamples();
    setDataVersion(Date.now());
  }

  return (
    <div className="d-flex flex-column">
      <div className="flex-grow-1">
        <PageBreadcrumbs pageName="Density Map" breadcrumbs={breadcrumbs} icon={iconSet.location} />
      </div>

      <Box>
        {project?.projectName && (
          <Box sx={{ mb: 2 }}>
            <ProjectInfo project={project} />
            {(dataKey && dataKeyByKey) ? formatUtcToLocalTz(dataKeyByKey.timestamp) : ''}
          </Box>
        )}
      </Box>

      <Box sx={myStyle.mapWrap}>
        <DensityMap
          dataVersion={dataVersion}
          cameraConfig={cameraConfig}
          showMapLayers={showMapLayers}
          iconInfo={iconInfo}
          origGeojsonData={origGeojsonData}
          geojsonData={geojsonData}
          hexGridGeoJson={hexgridFeatureCollectionRef.current}
          rectGeojson={rectGeojson}
          pwlTable={pwlTable}
          coreSamples={coreSamples}
        />
      </Box>

      <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept=".geojson,.csv"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fileInputRef.current.click()}
          sx={myStyle.geoJsonButton}
          disabled={isReplaying}
        >
          Open File
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={onReplayData}
          disabled={isReplaying || !(!!geojsonData)}
          sx={myStyle.replayButton}
        >
          Replay Data
        </Button>
        <Button 
          variant="contained" 
          color="secondary"
          sx={{ mr: 1 }}
          disabled={!isReplaying || !(!!geojsonData)}
          onClick={onStopReplay}
        >
          <StopIcon />
        </Button>
        <Button 
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
          disabled={!isReplaying || !(!!geojsonData)}
          onClick={onSkipReplay}
        >
          <SkipNextIcon />
        </Button>
      </Box>

      <Box sx={{ mb: 1 }}>
        <input
          type="file"
          ref={coreSampleFileInputRef}
          onChange={handleCoreSampleFileChange}
          style={{ display: 'none' }}
          accept=".csv"
        />
        <Button
          variant="contained"
          color="primary"
          sx={myStyle.geoJsonButton}
          onClick={() => coreSampleFileInputRef.current.click()}
          disabled={isReplaying}
        >
          Core Sample
        </Button>
      </Box>

      <Box sx={{ display: settings.showDebugLayers ? 'block' : 'none' }}>
        <Typography component="div" sx={{ fontWeight: 'bold' }}>DEBUG LAYERS</Typography>
        <FormGroup sx={{ flexDirection: 'row' }}>
          {showMapLayers.map((mapLayer) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={mapLayer.show}
                  onChange={(e) => handleLayerChange(mapLayer, e)}
                  name={mapLayer.label}
                />
              }
              label={mapLayer.label}
              key={mapLayer.id}
            />
          ))}
        </FormGroup>
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingInfo.loading}
      >
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" sx={{ mt: 2 }}>{loadingInfo.message}</Typography>
          </Box>
        </Box>
      </Backdrop>
    </div>
  );
};

export default DensityMapPage;

const ProjectInfo = ({ project }) => {
  const formatDateStr = (dateStr) => {
    if (!dateStr) return '-';
    return formatDate(dateStr, "yyyy-MM-dd'T'HH:mm:ss", 'MM/dd/yyyy');
  }

  return (
    <Box>
      <Typography variant="body1" sx={{ lineHeight: 1.2, color: '#012970' }}>Project</Typography>
      <Typography variant="subtitle2" sx={{ lineHeight: 1.2, color: '#012970', fontSize: '1.18rem' }}>{project.projectName}</Typography>
      {false && (
        <Typography variant="body2" sx={{ lineHeight: 1.2, color: '#012970' }}>
          {formatDateStr(project.startDate)} - {formatDateStr(project.endDate)}
        </Typography>
      )}
    </Box>
  );
}

const myStyle = {
  mapWrap: {
    position: 'relative',
    width: '100%',height: 'auto',
    '@media (min-width: 992px)': {
      height: '76vh'
    }
  },
  geoJsonButton: {
    marginRight: 2,
    width: 160,
  },
  replayButton: {
    marginRight: 1,
    width: 150
  }
};