import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { 
  selectLoggedIn,
  setLoggedIn,
 } from "src/features/loginSlice";
import { isJwtExpired } from "src/@core/utils/helper";

const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLoggedIn);
  const location = useLocation(); // Hook to detect route changes
  const token = localStorage.getItem("token");

  if (!loggedIn || isJwtExpired(token)) {
    localStorage.removeItem("token");
    dispatch(setLoggedIn(false));
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
