import * as h3 from "h3-js"
import * as turf from "@turf/turf";

export class HexbinProcessor {
  static getHexbinIndex(point, resolution) {
    const coords = point.geometry.coordinates;
    const index = h3.latLngToCell(coords[1], coords[0], resolution);

    return index;
  }

  /**
   * Updates the hexbins object with a new point.
   * 
   * WARNING: For performance this function is not pure and
   *   is updating hexgridFeatures by reference. This is assumed to minimize memory usage, reduce processing time, 
   *   and decrease the overhead from garbage collection if new objects were being created.
   */
  static updateHexgridFeatureWithPoint(hexgridFeatures, point) {
    const index = point.properties.hexbinIndex;

    if (!hexgridFeatures[index]) {
      hexgridFeatures[index] = this.createHexgridFeature(index);
    }

    const hexProperties = hexgridFeatures[index].properties;
    const oldMaxPassId = hexProperties.max_pass_id;
    const oldMaxTime = hexProperties.max_pass_time;
    const pointProperties = point.properties;

    // track the original data points (not the expanded points)
    hexProperties.point_ids[pointProperties.source_point_id] = 1;

    // track the unique pass ids
    hexProperties.pass_ids[pointProperties.passID] = 1;
    hexProperties.pass_count = Object.keys(hexProperties.pass_ids).length;

    // Store a running average of the points with the most recent passId in the current hexbin.
    // Pass IDs apparently are not incremented in chronological order so we need to check the timestamp also.
    if (!oldMaxPassId || (pointProperties.passID != oldMaxPassId && pointProperties.time > oldMaxTime)) {
      hexProperties.max_pass_id = pointProperties.passID;
      hexProperties.max_pass_time = pointProperties.time;
      hexProperties.max_pass_points = [point];
      hexProperties.max_pass_point_ids = new Set([pointProperties.source_point_id]);
      hexProperties.density = pointProperties.density;
      hexProperties.temperature = pointProperties.temperature;
      hexProperties.speed = pointProperties.speed;
    } else if (pointProperties.passID === oldMaxPassId && !hexProperties.max_pass_point_ids.has(pointProperties.source_point_id)) {
      hexProperties.max_pass_time = pointProperties.time > oldMaxTime ? pointProperties.time : oldMaxTime;
      hexProperties.max_pass_points.push(point);
      hexProperties.max_pass_point_ids.add(pointProperties.source_point_id);

      const count = hexProperties.max_pass_points.length;
      const invCount = 1 / count;

      // new average = old average * (n-1)/n + new value /n
      hexProperties.density = hexProperties.density * (count - 1) * invCount + pointProperties.density * invCount;
      hexProperties.temperature = hexProperties.temperature * (count - 1) * invCount + pointProperties.temperature * invCount;
      hexProperties.speed = hexProperties.speed * (count - 1) * invCount + pointProperties.speed * invCount;
    }
  }

  static getAveragePassIdPoint(passID, pointsInHexbin) {
    const pointsWithSamePassID = pointsInHexbin.filter((point) => point.properties.passID == passID);
    const count = pointsWithSamePassID.length;
    const lastPointIndex = count - 1;

    const density = pointsWithSamePassID.reduce((acc, point) => acc + point.properties.density, 0) / count;
    const temperature = pointsWithSamePassID.reduce((acc, point) => acc + point.properties.temperature, 0) / count;
    const speed = pointsWithSamePassID.reduce((acc, point) => acc + point.properties.speed, 0) / count;
    const headingDirection = this.convertHeadingToDirection(pointsWithSamePassID[lastPointIndex].properties.heading)

    return {
      geometry: pointsWithSamePassID[lastPointIndex].geometry,
      properties: {
        passID: passID,
        time: pointsWithSamePassID[lastPointIndex].properties.time,
        density: density,
        temperature: temperature,
        speed: speed,
        heading: pointsWithSamePassID[lastPointIndex].properties.heading,
        heading_direction: headingDirection,
      }
    }
  };

  static createHexgridFeature(index) {
    const hexBoundary = h3.cellToBoundary(index, true);

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [hexBoundary]
      },
      properties: {
        density: 0,
        pass_count: 0,
        pass_ids: {},
        point_ids: {},
        temperature: 0,
        speed: 0
      }
    }
  }

  static getHexgridFeatureFromCood(hexgridFeatures, longitude, latitude, resolution) {
    const index = this.getHexbinIndex(turf.point([longitude, latitude]), resolution);

    if (!hexgridFeatures[index]) {
      null;
    }

    return hexgridFeatures[index];
  }

  /**
   * Creates a rectangle GeoJSON feature around a point and rotates it to direction.
   * 
   * @param {Number} longitude
   * @param {Number} latitude
   * @param {number} width
   * @param {number} height
   * @param {number} direction
   * @return {Feature<Polygon>} - A GeoJSON polygon feature.
   */
  static getRectangleFeature(longitude, latitude, width, height, heading) {
    const center = turf.point([longitude, latitude]);
    const halfWidthKm = width / 2 / 1000;
    const halfHeightKm = height / 2 / 1000;

    let north = turf.destination(center, halfHeightKm, 0, { units: 'kilometers' });
    let south = turf.destination(center, halfHeightKm, 180, { units: 'kilometers' });

    let northeast = turf.destination(north, halfWidthKm, 90, { units: 'kilometers' });
    let southeast = turf.destination(south, halfWidthKm, 90, { units: 'kilometers' });
    let southwest = turf.destination(south, halfWidthKm, 270, { units: 'kilometers' });
    let northwest = turf.destination(north, halfWidthKm, 270, { units: 'kilometers' });

    let rectangleCoordinates = [
      northwest.geometry.coordinates,
      northeast.geometry.coordinates,
      southeast.geometry.coordinates,
      southwest.geometry.coordinates,
      northwest.geometry.coordinates // close the polygon
    ];

    let rectangle = turf.polygon([rectangleCoordinates], {});

    let rotatedRectangle = turf.transformRotate(rectangle, heading, { pivot: center });

    return rotatedRectangle;
  }

  /**
   * Given a center point (longitude, latitude) and the width and height of a rectangle,
   *  return an array of center points for smaller rectangles within the larger rectangle.
   * 
   * WARNING: For simplicity and performance this function assumes  
   *   very small distances relative to the earth's radius will be
   *   used and only has a rough approximation for curvature.
   * 
   * The length of 1 degree of latitude = 111000 meters (roughly).
   * The length of 1 degree of longitude = cosine(latitude) * length of 1 degree at the equator.
   * 
   * @param {Number} longitude
   * @param {Number} latitude
   * @param {any} width
   * @param {any} height
   * @param {any} rows
   * @param {any} columns
   * @param {Number} direction
   * @returns {Array} Array of center points for each smaller rectangle.
   */
  static expandPointInRectangle(longitude, latitude, width, height, rows, columns, direction) {
    const degreeLength = 111000;
    const degreeToRadian = Math.PI / 180;
    const smallHeight = height / rows;
    const smallWidth = width / columns;
    const center = turf.point([longitude, latitude]);

    // convert from meters to degrees
    const hDegreePerRow = smallHeight / degreeLength;
    const wDegreePerColumn = smallWidth / (degreeLength * Math.cos(latitude * degreeToRadian));

    const totalHeightDegrees = height / degreeLength;
    const totalWidthDegrees = width / (degreeLength * Math.cos(latitude * degreeToRadian));

    const points = [];

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < columns; col++) {
        const latOffsetForRow = hDegreePerRow * (row + 0.5) - (totalHeightDegrees / 2);
        const lonOffsetForColumn = wDegreePerColumn * (col + 0.5) - (totalWidthDegrees / 2);

        const centerLat = latitude + latOffsetForRow;
        const centerLon = longitude + lonOffsetForColumn;

        points.push([centerLon, centerLat]);
      }
    }

    points.push(points[0]); // close the polygon

    const polygon = turf.polygon([points]);
    const rotatedPolygon = turf.transformRotate(polygon, direction, { pivot: center });

    rotatedPolygon.geometry.coordinates[0].pop(); // remove the extra point

    return rotatedPolygon.geometry.coordinates[0];
  }

  static computePwlTable(geojsonData, rangeInput) {
    const ranges = rangeInput.map(r => parseFloat(r));

    let categorizedData = geojsonData.features.map(feature => {
      const density = feature.properties.density;

      if (density < ranges[0]) {
        return { bin: `< ${ranges[0].toFixed(1)}%` };
      }

      if (density >= ranges[0] && density <= ranges[1]) {
        return { bin: `${ranges[0].toFixed(1)} - ${ranges[1].toFixed(1)}%` };
      }

      if (density > ranges[1]) {
        return { bin: `> ${ranges[1].toFixed(1)}%` };
      }
    });

    // count occurrences in each bin
    const initialValue = {};
    let counts = categorizedData.reduce((accumulator, row) => {
      accumulator[row.bin] = (accumulator[row.bin] || 0) + 1;
      return accumulator;
    }, initialValue);

    const pwl = [
      {
        range: `< ${ranges[0].toFixed(1)}%`,
        percent: 0,
      },
      {
        range: `${ranges[0].toFixed(1)} - ${ranges[1].toFixed(1)}%`,
        percent: 0,
      },
      {
        range: `> ${ranges[1].toFixed(1)}%`,
        percent: 0,
      }
    ];


    // normalize counts to percentages
    let total = categorizedData.length;
    Object.keys(counts).map(bin => {
      // round percent to 1 decimal place
      const percent = Math.round((counts[bin] / total) * 100 * 10) / 10;

      const index = pwl.findIndex(p => p.range === bin);
      pwl[index].percent = percent;
    });

    return pwl;
  }

  static convertHeadingToDirection(angle) {
    // Normalize angle to be within the range [0, 360)
    let normalizedAngle = (angle + 360) % 360;

    let direction = 'Init';

    if (normalizedAngle >= 337.5 || normalizedAngle < 22.5) {
      direction = 'N';
    } else if (normalizedAngle >= 22.5 && normalizedAngle < 67.5) {
      direction = 'NE';
    } else if (normalizedAngle >= 67.5 && normalizedAngle < 112.5) {
      direction = 'E';
    } else if (normalizedAngle >= 112.5 && normalizedAngle < 157.5) {
      direction = 'SE';
    } else if (normalizedAngle >= 157.5 && normalizedAngle < 202.5) {
      direction = 'S';
    } else if (normalizedAngle >= 202.5 && normalizedAngle < 247.5) {
      direction = 'SW';
    } else if (normalizedAngle >= 247.5 && normalizedAngle < 292.5) {
      direction = 'W';
    } else if (normalizedAngle >= 292.5 && normalizedAngle < 337.5) {
      direction = 'NW';
    } else {
      direction = 'Unknown';
    }

    return direction;
  }
}